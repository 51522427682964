<template>
    <div class="d-flex flex-column">
        <v-alert color="green" border="left">
            多次购买会员时间可叠加, 购买后如果无法使用请联系客服
        </v-alert>

        <v-item-group mandatory v-model="selected">
            <v-container>
                <div class="mb-3 text-h6">套餐选择</div>
                <v-row>
                    <v-col v-for="(item, i) in items" :key="i" cols="12" md="6">
                        <v-item v-slot="{ active, toggle }">
                            <v-card :color="active ? 'info' : ''" height="200" @click="toggle" class="d-flex flex-column justify-center align-center">
                                <div class="text-button rounded-lg time">
                                    <v-btn class="text-none" outlined>{{ item.time }}</v-btn>
                                </div>
                                <div class="text-h5 mt-4 mb-4">¥ {{ item.price }} <span class="text-body-1"></span></div>
                                <div class="text-caption">不限设备</div>
                                <div class="text-caption">300GB/月</div>
                            </v-card>
                        </v-item>
                    </v-col>
                </v-row>

                <div class="d-flex align-center mt-5">
                    <span class="mr-3 text-body-2">支付选择</span>
                    <div>
                        <input type="radio" id="alipay" value="alipay" v-model="payType">
                        <label class="pl-2" for="alipay"><v-icon color='blue'>$vuetify.icons.alipay</v-icon></label>
                        <input type="radio" id="wechat" value="wechat" v-model="payType" class="ml-3">
                        <label class="pl-2"  for="wechat"><v-icon color='blue'>$vuetify.icons.wechat_pay</v-icon></label>
                    </div>
                </div>

                <div class="d-flex justify-end mt-5">
                    <v-btn :loading="payBtnLoading" color="primary" class="text-none" large @click="confirm">支付</v-btn>
                </div>
            </v-container>
        </v-item-group>
        <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card class="d-flex flex-column justify-center align-center" height="100vh">
                <v-card-title class="text-h5">{{ dialogTitle }}</v-card-title>
				<v-card-subtitle>
					付款完成后,请手动返回概览页查看会员信息
				</v-card-subtitle>
                <v-card-text class="d-flex justify-center align-center flex-grow-1">
                    <img :src="qrcodeData" alt="支付二维码" class="qrcode">
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                    <v-btn 
                        color="primary"
                        @click="dialog = false"
                    >
                    关闭
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import http from '../api/http';
import QRCode from 'qrcode';

export default {
    data: () => ({
        selected: 0,
        dialog: false,
        payType: "alipay",
        payBtnLoading: false,
        qrcodeData: null,
        items: [
            { 'product_id': '1m', 'time': '1个月', 'price': 15 },
            { 'product_id': '3m', 'time': '3个月', 'price': 38 }
        ],
    }),
    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.mobile;
        },
        dialogTitle() {
            return this.isMobile ? '不支持相册识别付款，只支持微信扫一扫付款' : '请使用微信扫一扫付款';
        },
        qrcodeSize() {
            return this.isMobile ? 400 : 200;
        }
    },
    methods: {
        async confirm() {
            this.payBtnLoading = true;

            if (this.payType != "wechat") {
                this.$root.$emit("show-snackbar", {
                    text: "如果长时间不跳转到支付页面，请换谷歌浏览器"
                });
            }

            let product_id = this.items[this.selected].product_id;
            let url = '/alipay/pay';

            // 根据支付类型更改 product_id 和 URL
            if (this.payType === "wechat") {
                url = '/wechat/pay';
                if (product_id === '1m') {
                    product_id = 'wx_1m';
                } else if (product_id === '3m') {
                    product_id = 'wx_3m';
                }
            }

            try {
                const data = {
                    "product_id": product_id,
                    "is_mobile": this.isMobile
                };

                const response = await http.post(url, data);

                this.payBtnLoading = false;

                if (this.payType === "wechat") {
                    // 微信支付，需要生成二维码
                    QRCode.toDataURL(response.data.data.url, { width: this.qrcodeSize, height: this.qrcodeSize })
                        .then(url => {
                            this.qrcodeData = url;
                            this.dialog = true;
                        })
                        .catch(err => {
                            console.error(err);
                            this.$root.$emit('show-snackbar', {
                                text: '生成二维码失败，请重试'
                            });
                        });
                } else {
                    // 支付宝支付，直接跳转
                    window.location.href = response.data.data.url;
                }
            } catch (error) {
                this.payBtnLoading = false;
                this.$root.$emit('show-snackbar', {
                    text: error.response.data.msg
                });
            }
        }
    }
};
</script>

<style scoped>
.qrcode {
    max-width: 70%;
    max-height: 70%;
}
</style>
